import React, {useState, useEffect} from "react";
import { FooterWidgets } from "@/data";
import SuizoLogo from "../images/suizo_blanco.svg"
import { graphql, useStaticQuery } from "gatsby"
import Reaptcha from "reaptcha";
import axios from "axios"
import iconMobile from "../images/mobile_footer.svg"


const Footer = () => {

  const [subscribing, setSubscribing] = useState(false)
  const [canSubscribe, setCanSubscribe] = useState(false)
  const [subscribeData, setSubscribeData] = useState({name:'',email:'', captchaVerified:false})
  const vw = 1200
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        fiscal_data
        contactMeans {
          phones
          mobile_phone
          email   
          socialMedia {
            name
            link
          }        
        }
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)
  const { phones, mobile_phone, email, socialMedia } = data.pharmacy.contactMeans;
  const { fiscal_data, env } = data.pharmacy;
  const [formVerification, setFormVerification] = useState({
    success: false,
    successMsg: "Se envió la solicitud correctamente",
    error: false,
    errorMsg: "Ha ocurrido un error",
  })

  const handleSubscribe = () => {

    setSubscribing(true);
    axios
      .post(`${env.APISERVER}/core/newsletter/`, { name: subscribeData.name, email: subscribeData.email, tenant: env.TENANTID })
      .then(response => {
        setFormVerification({
          ...formVerification,
          success: true,
          error:false,
          successMsg: "Gracias por suscribirse",
        })
        resetForm();
        setSubscribing(false);
      })
      .catch(error => {
        if (error.response) {
          setFormVerification({
            ...formVerification,
            success:false,
            error: true,
            errorMsg: "Ocurrió un error. Por favor, inténtelo nuevamente.",
          })
          setSubscribing(false);
        } else {
          setFormVerification({
            ...formVerification,
            success: true,
            successMsg: "Gracias por suscribirse",
          })
          setSubscribing(false);
        }
      })
  }

  const resetForm = () => {
    setSubscribeData({ ...subscribeData, name: '', email: '' })
  }  

    useEffect(() => {
      setCanSubscribe(!subscribing && subscribeData.captchaVerified && !!subscribeData.name && !!subscribeData.email)
    }, [subscribeData, subscribing])
  return (
    <footer className="footer_area">
 
      <div className="footer_content section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="footer_textwidget textwidget">
                <h2>{FooterWidgets.about.title}</h2>
                <ul style={{ color:'white', width: "100%", margin: 0, display: "flex", marginTop: "1rem", flexDirection: "column", alignItems: "left" }}>
                  {phones.map((phone, idx) => {
                    return (
                      <li key={idx}
                        style={{
                          display: "flex",
                          flexDirection: vw < 650 ? "column" : "row",
                        }}
                      >
                        <i className="icon-phone-call"
                          style={{
                            display: "flex",
                            width: vw < 650 ? "100%" : "10%",
                            alignItems: "center",
                            justifyContent: vw < 650 ? "center" : "flex-start",
                            paddingBottom: vw < 650 ? "5px" : "",
                          }}
                        />
                        <span
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "flex-end",
                            justifyContent: vw < 650 ? "center" : "flex-start",
                          }}
                        >
                          {phone}
                        </span>
                      </li>
                    )
                  })
                  }
                  {mobile_phone &&
                    <li
                      style={{
                        display: "flex",
                        flexDirection: vw < 650 ? "column" : "row",
                      }}
                    >
                      {/* <i className="fa fa-mobile-phone fa-lg ps-2 pe-2"
                        style={{
                          display: "flex",
                          width: vw < 650 ? "100%" : "10%",
                          alignItems: "center",
                          justifyContent: vw < 650 ? "center" : "flex-start",
                          paddingBottom: vw < 650 ? "5px" : "",
                        }}
                      /> */}
                      <span className='d-flex justify-content-start' style={{width: '10%'}}>
                        <img src={iconMobile} width='25px' alt=""></img>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "flex-end",
                          justifyContent: vw < 650 ? "center" : "flex-start",
                          // paddingLeft: vw < 650 ? "" : ".3em",
                        }}
                      >
                        {mobile_phone}
                      </span>
                    </li>
                  }
                  <li
                    style={{
                      display: "flex",
                      flexDirection: vw < 650 ? "column" : "row"
                    }}
                  >
                    <a href={`mailto:${email}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: vw < 650 ? "column" : "row"
                      }}
                    >
                      <i className="icon-contact footer-link"
                        style={{
                          
                          display: "flex",
                          width: vw < 650 ? "100%" : "10%",
                          alignItems: "center",
                          justifyContent: vw < 650 ? "center" : "flex-start",
                          paddingBottom: vw < 650 ? "5px" : "",
                        }}
                      />
                      <span id="icon_dollar" 
                        className="footer-link"
                        style={{
                          
                          display: "flex",
                          width: "100%",
                          alignItems: "flex-end",
                          justifyContent: vw < 650 ? "center" : "flex-start",
                        }}
                      >
                        {email}
                      </span>
                    </a>
                  </li>
                </ul>

              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="newslatter">
                <h2>Promociones</h2>
                <h3>Recibí nuestras promociones exclusivas</h3>
                <input
                  type="TEXT"
                  placeholder="Tu nombre"
                  value={subscribeData.name}
                  onChange={(e)=>setSubscribeData({...subscribeData, name:e.target.value})}
                />
                <input
                  type="EMAIL"
                  placeholder="Tu Email"
                  value={subscribeData.email}
                  onChange={(e) => setSubscribeData({ ...subscribeData, email: e.target.value })}
                />
                <div className="d-flex flex-wrap align-items-center justifiy-content-center-mobile">
                  <Reaptcha
                    className="mb-3"
                  sitekey="6LeeyzscAAAAAP83mITcGIFEreUNXF8wmSREAEmR"
                    hl='es-419'
                    onVerify={() => setSubscribeData({...subscribeData, captchaVerified:true})}
                    onExpire={() => setSubscribeData({ ...subscribeData, captchaVerified: false })}

                >
                  </Reaptcha>
                  <div className="d-flex justify-content-center flex-fill mb-3">
                    <button
                      className={`ms-2 w-100 reCaptcha_height btn-yellow${canSubscribe?'':'-disabled'}`}
                      type='button'
                      onClick={handleSubscribe}
                      disabled={!canSubscribe}
                    >
                      SUBSCRIBIRME
                    </button>
                   
                  </div>
             

                </div> {formVerification.success && (
                      <span style={
                        {
                          display: 'flex',
                          width: '100%',
                          color: 'green',
                          backgroundColor: '#DEF0D8',
                          marginTop: '20px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          // justifyContent: 'flex-start',
                          padding: '10px 30px',
                          fontSize: '14px',
                        }
                      } className="success1" id="success">
                        {formVerification.successMsg}
                      </span>
                    )}
                    {formVerification.error && (
                      <span style={
                        {
                          display: 'flex',
                          width: '100%',
                          color: 'red',
                          backgroundColor: '#f3cbd6',
                          marginTop: '20px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          // justifyContent: 'flex-start',
                          padding: '10px 30px',
                          fontSize: '14px',
                        }
                      } className="success1" id="success">
                        {formVerification.errorMsg}
                      </span>
                    )}
              </div>
            </div>
          </div>
          <div className="copyright_area">
            <div className="row">
              <div className="col-md-4 col-sm-4 copyright_text ">
                <p className="text-center">
                  Powered by MediaCore Pharma.<br></br>
                  © 2022 Todos los derechos reservados.
                </p>
              </div>

              <div className="col-md-4 col-sm-4 " style={{ marginBottom: vw > 674 ? "1.5rem" : "0", marginTop: "1.5rem", alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div className="detail" style={{ display: "flex", order: "1" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: vw > 674 ? "row" : "column",
                        justifyContent: vw > 674 ? "row" : "space-between",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <li style={{ paddingBottom: vw > 674 ? "0" : "10px" }}>
                        <a
                          href="https://suizoargentina.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="Suizo"
                            style={{ width: vw < 400 ? "20vw" : "5rem", marginRight: "1em" }}
                            src={SuizoLogo}
                          />
                        </a>
                      </li>
                      <li style={{ paddingBottom: vw > 674 ? "0" : "10px" }}>
                        <a
                          href="https://unixono.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="Unixono"
                            src="https://storage.googleapis.com/farmawebs_default_storage_bucket/unixono.png"
                            style={{ width: vw < 400 ? "20vw" : "5rem", marginRight: "1em" }}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-center col-md-4 col-sm-4 copyright_social text-end">
                <div>
                  <ul className="justify-content-center">
                  {socialMedia?.map(({ link, name }, index) => (
                    <li key={`footer-social-link-${index}`}>
                      <a href={link}>
                        <i className={`fa ${name === 'Facebook' ? 'fa-facebook-f' :
                              name === 'Instagram' ? 'fa-instagram' :
                              name === 'Linkedin' ? 'fa-linkedin' :
                              name === 'Youtube' ? 'fa-youtube-play' :
                              name === 'Twitter' ? 'fa-twitter' :
                                  ''
                          }`}></i>
                      </a>
                    </li>
                  ))}               
                </ul>
               </div>
                
                <div className="text-center ms-3">
                  <a
                    href={fiscal_data}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Data Fiscal"
                      src="https://storage.googleapis.com/farmawebs_default_storage_bucket/QR.png"
                      style={{ width: vw < 400 ? "15vw" : "2.5rem" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
